import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
// import { environment } from 'src/environments/environment';
import { StorageService } from '../services/storage/storage.service';
import { ConfigEnvService } from '../services/config-env/config-env.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class JwtInterceptor implements HttpInterceptor {
    environment : any = {};

    constructor(
        private storageService: StorageService,
        private configEnvService: ConfigEnvService,
        private http: HttpClient
    ) {
        // this.configEnvService.getEnvironment().subscribe(env => {
        //     this.environment = env;
        // });

        // this.environment.base_api = this.configEnvService.getBasePath();
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to api url
        this.environment.base_api = this.configEnvService.getBasePath();
        // this.environment.base_api = window.location.origin + ":8088";
        const user = this.storageService.getUser();
        const isLoggedIn = user && user.token;
        let isApiUrl;

        if(this.environment.base_api){
            isApiUrl = request.url.startsWith(this.environment.base_api);
        }else{
            isApiUrl = request.url.startsWith(window.location.origin + ":8088");
        }

        if (isLoggedIn && isApiUrl) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${user.token}`
                }
            });
        }

        return next.handle(request);
    }

}