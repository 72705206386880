/*----------------------------------------------------
===> H.Z : Import Dependencies
------------------------------------------------------*/

import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ConfigEnvService } from 'src/app/shared/services/config-env/config-env.service';

/*------------------------------------------------------
===> H.Z : Body of the Component  'AppComponent'
------------------------------------------------------*/

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  title = 'rKube-front';
  localData: string;

  constructor(
    private http: HttpClient,
    private configEnvService: ConfigEnvService
  ) {}
  
  ngOnInit() : void {
    // this.localData = window.localStorage.getItem('base-api');
    // this.http.get<any>('/assets/environment.json').subscribe(data => {
    //   this.configEnvService.setEnvironment(data);
    //   if(this.localData !== data.base_api){
    //     window.localStorage.setItem('base-api', data.base_api);
    //   } 
    // });
  }

}
