import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

const USER_KEY = 'auth-user';

@Injectable({
  providedIn: 'root'
})

export class StorageService {
  constructor(
    private cookieService: CookieService
  ) {}

  clean(): void {
    window.sessionStorage.clear();
  }

  public saveInLocalStorage(key_item, value){
    window.sessionStorage.removeItem(key_item);
    this.setCookie(key_item, JSON.stringify(value));
  }

  public getLocalStorage(key_item){
    const tempData = this.cookieService.get(key_item);
    if(tempData) return JSON.parse(tempData);
    return null;
  }

  public saveUser(user: any, remember : boolean): void {

    if(remember){
      // If RememberMe Checked : Store in localStorage
      this.cookieService.delete(USER_KEY, '/');
      this.setCookie(USER_KEY, JSON.stringify(user));
      window.localStorage.removeItem(USER_KEY);
      window.localStorage.setItem(USER_KEY, JSON.stringify(user));
    }else{
      // Else : Store in sessionStorage
      this.cookieService.delete(USER_KEY, '/');
      this.setCookie(USER_KEY, JSON.stringify(user));
    }

  }

  public getUser(): any {
    const user = this.cookieService.get(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }else{
      const user = window.localStorage.getItem(USER_KEY);
      // this.setCookie(USER_KEY, JSON.stringify(user));
      if (user) {
        return JSON.parse(user);
      }
    }

    return {};
  }

  public isLoggedIn(): boolean {
    const user = this.cookieService.get(USER_KEY);
    if (user) {
      return true;
    }else{
      const user = window.localStorage.getItem(USER_KEY);
      if (user) {
        return JSON.parse(user);
      }
    }

    return false;
  }

  // Fonction pour définir un cookie avec une expiration de 24 heures
  setCookie(name: string, value: string) {
    const now = new Date();
    const expiration = new Date(now.getTime() + 24 * 3600 * 1000); // 24 heures
    this.cookieService.set(name, value, expiration, '/');
  }
}
