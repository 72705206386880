/*----------------------------------------------------
===> H.Z : Import Dependencies
------------------------------------------------------*/

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../shared/helpers/auth.guard';
import { LicenseGuard } from '../shared/services/license/license.guard';
import { Role } from '../shared/models/role.model';

/*----------------------------------------------------------
===> H.Z : Body of the Routing principale 'ViewsRoutingModule'
----------------------------------------------------------*/

const routes: Routes = [
  /*----------------------------------------------------------
  ===> H.Z : Routes Logged-Out
  ----------------------------------------------------------*/
  
  {
    path: 'auth',
    loadChildren: () => import('./auth-view/auth-view.module').then(m => m.AuthViewModule),
  },
  
  {
    path: 'request-demo',
    loadChildren: () => import('./auth-view/pages/request-demo/request-demo.module').then(m => m.RequestDemoModule),
  },
  
  /*----------------------------------------------------------
  ===> H.Z : Routes Errors 4XX-5XX
  ----------------------------------------------------------*/
  
  {
    path: 'error',
    loadChildren: () => import('./errors-view/errors.module').then(m => m.ErrorsModule),
  },

  /*----------------------------------------------------------
  ===> H.Z : Routes Logged-In
  ----------------------------------------------------------*/
  
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modernization-hub-view/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
    data: { roles: [Role.Admin, Role.CMO, Role.ProjectManager, Role.Auditor, Role.User] },
  },
  {
    path: 'projects',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modernization-hub-view/modernization-hub.module').then(m => m.ModernizationHubViewModule),
    data: { roles: [Role.Admin, Role.CMO, Role.ProjectManager, Role.Auditor, Role.User] },
  },  
  {
    path: 'projects/renovate',
    canActivate: [AuthGuard],
    loadChildren: () => import('./renovate-view/renovate-view.module').then(m => m.RenovateViewModule),
    data: { roles: [Role.Admin, Role.CMO, Role.ProjectManager, Role.Auditor, Role.User], currentJourney : 'renovate'},
  },
  {
    path: 'projects/replatform',
    canActivate: [AuthGuard],
    loadChildren: () => import('./replatform-view/replatform-view.module').then(m => m.ReplatformViewModule),
    data: { roles: [Role.Admin, Role.CMO, Role.ProjectManager, Role.Auditor, Role.User], currentJourney : 'replatform'},
  },
  {
    path: 'projects/refactor',
    canActivate: [AuthGuard, LicenseGuard],
    loadChildren: () => import('./refactor-view/refactor-view.module').then(m => m.RefactorViewModule),
    data: { roles: [Role.Admin, Role.CMO, Role.ProjectManager, Role.Auditor, Role.User], currentJourney : 'refactor'},
  },
  {
    path: 'projects/rebuild',
    canActivate: [AuthGuard],
    loadChildren: () => import('./rebuild-view/rebuild-view.module').then(m => m.RebuildViewModule),
    data: { roles: [Role.Admin, Role.CMO, Role.ProjectManager, Role.Auditor, Role.User], currentJourney : 'rebuild'},
  },
  {
    path: 'projects/restrain',
    canActivate: [AuthGuard],
    loadChildren: () => import('./restrain-view/restrain-view.module').then(m => m.RestrainViewModule),
    data: { roles: [Role.Admin, Role.CMO, Role.ProjectManager, Role.Auditor, Role.User], currentJourney : 'restrain'},
  },
  {
    path: 'projects/rehost',
    canActivate: [AuthGuard],
    loadChildren: () => import('./rehost-view/rehost-view.module').then(m => m.RehostViewModule),
    data: { roles: [Role.Admin, Role.CMO, Role.ProjectManager, Role.Auditor, Role.User], currentJourney : 'rehost'},
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadChildren: () => import('./settings-view/settings-view.module').then(m => m.SettingsViewModule),
    data: { roles: [Role.Admin, Role.CMO, Role.ProjectManager, Role.Auditor, Role.User] },
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    loadChildren: () => import('./profile-view/profile-view.module').then(m => m.ProfileViewModule),
    data: { roles: [Role.Admin, Role.CMO, Role.ProjectManager, Role.Auditor, Role.User] },
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {})
  ],
  exports: [RouterModule]
})

export class ViewsRoutingModule { }