/*----------------------------------------------------
===> H.Z : Import Dependencies
------------------------------------------------------*/

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/*----------------------------------------------------
===> H.Z : Import Modules
------------------------------------------------------*/

import { ViewsRoutingModule } from './views.routing.module';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { LicenseControllerService } from 'rkube_modules/rkube-api';
import { LicenseService } from '../shared/services/license/license.service';

/*------------------------------------------------------
===> H.Z : Body of the module principale 'ViewsModule'
------------------------------------------------------*/

@NgModule({
  imports: [
    CommonModule,
    ViewsRoutingModule,
    HttpClientModule,
    RouterModule
  ],
  providers: [
    LicenseControllerService,
    LicenseService
  ],
  exports: [
    HttpClientModule,
    RouterModule
  ],
})


export class ViewsModule { }
