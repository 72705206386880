/*----------------------------------------------------
===> H.Z : Import Dependencies
------------------------------------------------------*/

import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { MessageService } from 'primeng/api';
// import { environment } from 'src/environments/environment';
import { ApiModule, BASE_PATH } from 'rkube_modules/rkube-api';  

/*----------------------------------------------
===> H.Z : Modules principale de l'app
----------------------------------------------*/

import { AppRoutingModule } from './app.routing.module';
// import { CoreModule } from './core/core.module';
import { ViewsModule } from './views/views.module'

/*----------------------------------------------
===> H.Z : Import Interceptor's
----------------------------------------------*/

import { JwtInterceptor } from './shared/helpers/jwt.interceptor';
import { ErrorInterceptor } from './shared/helpers/error.interceptor';

/*----------------------------------------------
===> H.Z : Components principale de l'app
----------------------------------------------*/

import { AppComponent } from './app.component';
// import { BaseApiService } from 'src/app/shared/services/base_api.service';


/*------------------------------------------------------
===> H.Z : Body of the module principale 'AppModule'
------------------------------------------------------*/

@NgModule({
  schemas: [NO_ERRORS_SCHEMA],
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    // CoreModule,
    ViewsModule,
    ApiModule,
    HttpClientModule,
  ],
  providers: [
    MessageService,
    // { provide: BASE_PATH, useFactory: (dropdownService: BaseApiService) => dropdownService.baseApi.code, deps: [BaseApiService] },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],  
  bootstrap: [AppComponent]
})

export class AppModule { }
