import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as environment from 'src/assets/environment.json';

@Injectable({
  providedIn: 'root'
})

export class ConfigEnvService {

  private environment: any = environment;

  constructor() { }
  
  getEnvironment(): Observable<any> {
    // Vérifier si les données d'environnement ont déjà été chargées
    return this.environment;
  }

  setEnvironment(data) {
    // Vérifier si les données d'environnement ont déjà été chargées
    // console.log('setEnvironment');
    this.environment = data;
    console.log(this.environment);
    console.log(this.environment['hostname']);
  }

  // Méthode pour récupérer une variable d'environnement spécifique
  getEnvironmentVariable(variableName: string): any {
    console.log(this.environment);
    console.log(this.environment['hostname']);
    return this.environment[variableName];
  }

  getBasePath(){
    return window.localStorage.getItem('base-api');
  }
}
