/*----------------------------------------------------
===> H.Z : Import Dependencies
------------------------------------------------------*/

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';

/*----------------------------------------------------------
===> H.Z : Body of the Routing principale 'AppRoutingModule'
----------------------------------------------------------*/

const routes: Routes = [
  {
    path: '',
    redirectTo : 'auth/login',
    pathMatch: 'full'
    // component: AppComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {})
  ],
  exports: [RouterModule]
})

export class AppRoutingModule { }