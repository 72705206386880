import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, timer, of } from 'rxjs';
import { catchError, switchMap, retry, delay, mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';

import { AuthService } from '../services/auth/auth.service';
import { UtilitiesControllerService } from 'rkube_modules/rkube-api';
import { MessageService } from 'primeng/api';

// export let counter = 0; // Counter
// export const maxRetries = 1; // maximum number of retries to perform
// export const delayMs = 1000; // delay time between each retry

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  private counter = 0; // Counter
  private readonly maxRetries = 1; // maximum number of retries to perform
  private readonly delayMs = 1000; // delay time between each retry

  constructor(
    private router: Router, // dependency injection for Router
    private authenticationService: AuthService, // dependency injection for AuthService
    private messageService: MessageService, // dependency injection for MessageService
    private utilitiesControllerService: UtilitiesControllerService // dependency injection for UtilitiesControllerService
  ) {}

  

  // implementation of HttpInterceptor's intercept method
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request)
      .pipe(
        // retry failed request
        catchError((error: HttpErrorResponse) => {
          let errorMessage = ''; // variable to store error message

          switch (error.status) {
            case 400:
              errorMessage = 'Bad Request';
              break;
            case 401:
              errorMessage = 'Unauthorized';
              break;
            case 403:
              errorMessage = 'Forbidden';
                    
                if (this.counter === this.maxRetries) {
                    // return throwError(new Error('Max retries reached'));
                    return throwError(() => new Error('Max retries reached'));
                }else{
                    this.checkApiHealth(); // call to check API health
                    this.counter++; // Count Error Api Check
                }

              break;
            case 404:
              errorMessage = 'Not Found';
              break;
            case 500:
              errorMessage = 'Internal Server Error';
              break;
            default:
              // errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
              errorMessage = `${error.error.message}`;
              break;
          }
  
          if(error.status != 403){
              this.messageService.add({key: 'tr', severity: 'error', summary: 'Error Message', detail: errorMessage }); // display error message
          }

          console.log(errorMessage); // log error message
          
          return throwError(() => error);
        })
      );
  }


    // check API health
    checkApiHealth() {
        return this.utilitiesControllerService.getAllTags().subscribe({
            next: data => {
                console.log(data);
                this.messageService.add({key: 'tr', severity: 'error', summary: 'Unauthorized', detail: 'Sorry, your request could not be processed.'});
            },
            error: err => {
                this.messageService.add({key: 'tr', severity: 'error', summary: 'Session Expired', detail: 'Please Log in again.'});
                this.authenticationService.logout(); // log out
                console.log(err);
                return of(err).pipe(
                    delay(this.delayMs),
                    switchMap(async () => this.checkApiHealth())
                );
            }
        });


    }

}
