import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AnalyticsControllerService } from './api/analyticsController.service';
import { AuthenticationControllerService } from './api/authenticationController.service';
import { LicenseControllerService } from './api/licenseController.service';
import { ProjectControllerService } from './api/projectController.service';
import { RebuildControllerService } from './api/rebuildController.service';
import { RefactorControllerService } from './api/refactorController.service';
import { RenovateControllerService } from './api/renovateController.service';
import { RestrainControllerService } from './api/restrainController.service';
import { UserControllerService } from './api/userController.service';
import { UtilitiesControllerService } from './api/utilitiesController.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AnalyticsControllerService,
    AuthenticationControllerService,
    LicenseControllerService,
    ProjectControllerService,
    RebuildControllerService,
    RefactorControllerService,
    RenovateControllerService,
    RestrainControllerService,
    UserControllerService,
    UtilitiesControllerService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
