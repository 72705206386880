import { Injectable } from '@angular/core';
// import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

// const AUTH_API = environment.base_api + '/auth/';
const USER_KEY = 'auth-user';

// const httpOptions = {
//   headers: new HttpHeaders(
//     { 
//       'Content-Type': 'application/json',
//       'Accept' : '*/*'
//     }
//   )
// };

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  private userSubject: BehaviorSubject<any>;
  public user: Observable<any>;

  constructor(
    private router: Router, 
    private cookieService: CookieService
  ) { }

  // login(
  //   email: string, 
  //   password: string
  // ): Observable<any> {
  //   return this.http.post(
  //     AUTH_API + 'authenticate',
  //     {
  //       email,
  //       password,
  //     },
  //     httpOptions
  //   );
  // }

  // register(
  //   firstName: string, 
  //   lastName: string, 
  //   email: string, 
  //   password: string
  // ): Observable<any> {
  //   return this.http.post(
  //     AUTH_API + 'register',
  //     {
  //       firstName,
  //       lastName,
  //       email,
  //       password,
  //     },
  //     httpOptions
  //   );
  // }

  logout() {
    // return this.http.post(AUTH_API + 'signout', { }, httpOptions);
    window.sessionStorage.removeItem(USER_KEY);
    this.cookieService.delete(USER_KEY, '/');
    window.localStorage.removeItem(USER_KEY);
    this.router.navigate(['/auth/login']);
  }
}
