import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LicenseService } from './license.service';

@Injectable({ providedIn: 'root' })

export class LicenseGuard implements CanActivate {
    constructor(
        private licenseService: LicenseService, 
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        console.log(route);
        const moduleName = route.data['currentJourney']; // Récupérer le nom du module depuis les données de la route

        console.log(moduleName);

        this.licenseService.isModuleAccessible(moduleName).then(
            (response) => {
                console.log(response);

                if (!response) {
                    // Rediriger l'utilisateur ou afficher un message d'erreur
                    this.router.navigate(['/dashboard'], { queryParams : {error : "no_license"}});
                    return false;
                }

                return true;

            },
            (error) => {
                console.log(error);
            }
        )
    }
}