import { Injectable } from '@angular/core';
import { LicenseControllerService } from 'rkube_modules/rkube-api';

@Injectable({
  providedIn: 'root'
})

export class LicenseService {
  private license: any;
  private modules: any;

  constructor(
    private licenseControllerService: LicenseControllerService
  ) {
    // this.getLicense().then(
    //   (response) => {
    //     this.modules = response
    //   }
    // );
  }


  getLicense(): Promise<any> {
    // Effectuer une requête HTTP pour vérifier l'état de la licence
    return this.licenseControllerService.getLicenses()
      .toPromise()
        .then(response => {
          this.license = response;
          return this.license;
        })
        .catch(error => {
          console.error('Erreur lors de la vérification de la licence : ', error);
          return false;
        });
  }

  checkLicenseValidity(): Promise<boolean> {
    // Effectuer une requête HTTP pour vérifier l'état de la licence
    return this.licenseControllerService.getLicense("")
      .toPromise()
        .then(response => {
          this.license = response;
          return true;
        })
        .catch(error => {
          console.error('Erreur lors de la vérification de la licence : ', error);
          return false;
        });

  }

  async isModuleAccessible(moduleName: string): Promise<any> {
    // Logique pour vérifier si le module est accessible en fonction de l'état de la licence
    // Utilisez this.license pour vérifier l'état de la licence
    // Retourne true si le module est accessible, false sinon

    // if(!this.modules){
      await this.getLicense().then(
        (response) => {
          this.modules = response
        }
      );
    // }
console.log(this.modules);

    const module = this.modules.find(module => module.moduleName.toLowerCase() === moduleName);
    return module ? module.isLicensed : false;
  }
}
