/**
 * Rkube API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { RestResponse } from '../model/restResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class RestrainControllerService {

    protected basePath = 'https://app.rkube.io:8088';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param resultId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVulnerabilityReport(resultId: string, observe?: 'body', reportProgress?: boolean): Observable<RestResponse>;
    public getVulnerabilityReport(resultId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponse>>;
    public getVulnerabilityReport(resultId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponse>>;
    public getVulnerabilityReport(resultId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (resultId === null || resultId === undefined) {
            throw new Error('Required parameter resultId was null or undefined when calling getVulnerabilityReport.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (resultId !== undefined && resultId !== null) {
            queryParameters = queryParameters.set('resultId', <any>resultId);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<RestResponse>('get',`${this.basePath}/api/v1/restrain/vulnerabilityreport`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param source 
     * @param sourceType 
     * @param vulnerabilityScan 
     * @param secretScan 
     * @param misconfigScan 
     * @param licenseScan 
     * @param complianceCheck 
     * @param imageMisconfigCheck 
     * @param imageSecretsCheck 
     * @param projectId 
     * @param providerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public initScan(source: string, sourceType: string, vulnerabilityScan: boolean, secretScan: boolean, misconfigScan: boolean, licenseScan: boolean, complianceCheck: boolean, imageMisconfigCheck: boolean, imageSecretsCheck: boolean, projectId: number, providerId?: number, observe?: 'body', reportProgress?: boolean): Observable<RestResponse>;
    public initScan(source: string, sourceType: string, vulnerabilityScan: boolean, secretScan: boolean, misconfigScan: boolean, licenseScan: boolean, complianceCheck: boolean, imageMisconfigCheck: boolean, imageSecretsCheck: boolean, projectId: number, providerId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RestResponse>>;
    public initScan(source: string, sourceType: string, vulnerabilityScan: boolean, secretScan: boolean, misconfigScan: boolean, licenseScan: boolean, complianceCheck: boolean, imageMisconfigCheck: boolean, imageSecretsCheck: boolean, projectId: number, providerId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RestResponse>>;
    public initScan(source: string, sourceType: string, vulnerabilityScan: boolean, secretScan: boolean, misconfigScan: boolean, licenseScan: boolean, complianceCheck: boolean, imageMisconfigCheck: boolean, imageSecretsCheck: boolean, projectId: number, providerId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (source === null || source === undefined) {
            throw new Error('Required parameter source was null or undefined when calling initScan.');
        }

        if (sourceType === null || sourceType === undefined) {
            throw new Error('Required parameter sourceType was null or undefined when calling initScan.');
        }

        if (vulnerabilityScan === null || vulnerabilityScan === undefined) {
            throw new Error('Required parameter vulnerabilityScan was null or undefined when calling initScan.');
        }

        if (secretScan === null || secretScan === undefined) {
            throw new Error('Required parameter secretScan was null or undefined when calling initScan.');
        }

        if (misconfigScan === null || misconfigScan === undefined) {
            throw new Error('Required parameter misconfigScan was null or undefined when calling initScan.');
        }

        if (licenseScan === null || licenseScan === undefined) {
            throw new Error('Required parameter licenseScan was null or undefined when calling initScan.');
        }

        if (complianceCheck === null || complianceCheck === undefined) {
            throw new Error('Required parameter complianceCheck was null or undefined when calling initScan.');
        }

        if (imageMisconfigCheck === null || imageMisconfigCheck === undefined) {
            throw new Error('Required parameter imageMisconfigCheck was null or undefined when calling initScan.');
        }

        if (imageSecretsCheck === null || imageSecretsCheck === undefined) {
            throw new Error('Required parameter imageSecretsCheck was null or undefined when calling initScan.');
        }

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling initScan.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (source !== undefined && source !== null) {
            queryParameters = queryParameters.set('source', <any>source);
        }
        if (sourceType !== undefined && sourceType !== null) {
            queryParameters = queryParameters.set('sourceType', <any>sourceType);
        }
        if (providerId !== undefined && providerId !== null) {
            queryParameters = queryParameters.set('providerId', <any>providerId);
        }
        if (vulnerabilityScan !== undefined && vulnerabilityScan !== null) {
            queryParameters = queryParameters.set('vulnerabilityScan', <any>vulnerabilityScan);
        }
        if (secretScan !== undefined && secretScan !== null) {
            queryParameters = queryParameters.set('secretScan', <any>secretScan);
        }
        if (misconfigScan !== undefined && misconfigScan !== null) {
            queryParameters = queryParameters.set('misconfigScan', <any>misconfigScan);
        }
        if (licenseScan !== undefined && licenseScan !== null) {
            queryParameters = queryParameters.set('licenseScan', <any>licenseScan);
        }
        if (complianceCheck !== undefined && complianceCheck !== null) {
            queryParameters = queryParameters.set('complianceCheck', <any>complianceCheck);
        }
        if (imageMisconfigCheck !== undefined && imageMisconfigCheck !== null) {
            queryParameters = queryParameters.set('imageMisconfigCheck', <any>imageMisconfigCheck);
        }
        if (imageSecretsCheck !== undefined && imageSecretsCheck !== null) {
            queryParameters = queryParameters.set('imageSecretsCheck', <any>imageSecretsCheck);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<RestResponse>('post',`${this.basePath}/api/v1/restrain/${encodeURIComponent(String(projectId))}/imagescan`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
